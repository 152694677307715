import {darkTheme} from "./dark-theme";
import {lightTheme} from "./light-theme";
import {Theme} from "./theme.model";
import {LocalStorageKeysEnum} from "../../services/localstorage/local-storage-keys.enum";

const sgProperties = {
  "--logo-one-width": "250px",
  "light": {
    "--background-logo": "url('/assets/default-logos/smartglobe/Smartglobe.svg') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/default-logos/smartglobe/Smartglobe.svg') left center no-repeat",
  }
}

const saclayProperties = {
  "--font-secondary": "#ab1761",
  "--background-primary": "#fae000",
  "--background-secondary": "#222221",
  "--background-tertiary": "#d95353",
  "--background-primary-light": "#fae0001A",
  "--error-default": "#ef3e36",
  "--background-size": "auto 100%",
  "--logo-one-width": "170px",
  "light": {
    "--background-logo": "url('/assets/clients-logos/saclay/paris-saclay-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/saclay/paris-saclay-dark.png') left center no-repeat",
  }
};

const anthalpiaProperties = {
  "--background-primary": "#fae000",
  "--background-primary-light": "#fae000",
  "light": {
    "--background-logo": "url('/assets/clients-logos/anthalpia/anthalpia-logo-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/anthalpia/anthalpia-logo-dark.png') left center no-repeat",
  }
};

const capdeseineProperties = {
  "--font-secondary": "#B0F2B6",
  "--font-quaternary": "#B0F2B6",
  "--background-primary": "#B0F2B6",
  "--background-primary-light": "#fae0001A",
  "light": {
    "--background-logo": "url('/assets/clients-logos/capdeseine/logo-capdeseine-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/capdeseine/logo-capdeseine-dark.png') left center no-repeat",
  }
};

const croissyProperties = {
  "--background-primary": "#4AAFDB",
  "--background-primary-light": "#fae0001A",
  "--background-logo": "url('/assets/clients-logos/croissy/logo-header.png') left center no-repeat",
};
const douaneProperties = {
  "--font-button-primary": "#fff",
  "--font-primary": "#fff",
  "--font-secondary": "#2972CE",
  "--font-tertiary": "#800000",
  "--font-quaternary": "#fff",
  "--background-primary": "#2972CE",
  "--background-tertiary": "#800000",
  "--background-primary-light": "#2972CE1A",
  "--background-logo": "url('/assets/clients-logos/douane/douane.svg') left center no-repeat",
};

const effysensProperties = {
  "--font-button-primary": "#fff",
  "--font-primary": "#fff",
  "--font-secondary": "#de740a",
  "--font-quaternary": "#de740a",
  "--background-primary": "#474747",
  "--background-primary-light": "#fae0001A",
  "--background-logo-color": "#222221",
  "--logo-one-width": "210px",
  "light": {
    "--background-logo": "url('/assets/clients-logos/effysens/effysens-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/effysens/effysens-dark.png') left center no-repeat",
  }
}

const electroluxProperties = {
  "--background-primary": "#004486",
  "--background-tertiary": "#fff",
  "--background-primary-light": "#fae0001A",
  "--logo-one-width": "250px",
  "--label-font-color": "#FFFFFF",
  "light": {
    "--background-logo": "url('/assets/clients-logos/electrolux/Electrolux-logo-light.svg') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/electrolux/Electrolux-logo-dark.svg') left center no-repeat",
  }
};
const hoganlovellsProperties = {
  "--font-secondary": "#b5dc17",
  "--font-quaternary": "#b5dc17",
  "--background-primary": "#b5dc17",
  "--background-secondary": "#231f20",
  "--background-primary-light": "#b5dc17",
  "--background-logo": "url('/assets/clients-logos/hoganlovells/hoganlovells_logo.svg') left center no-repeat",
};
const imagineProperties = {
  "--font-secondary": "#FAE000",
  "--font-tertiary": "#222221",
  "--background-primary": "#B1268B",
  "--background-primary-light": "#fae0001A",
  "--logo-one-width": "120px",
  "--logo-one-height": "50px",
  "--background-logo": "url('/assets/clients-logos/imagine/imagine-new.png') left center no-repeat",
  "--background-size-2": "auto 100%",
  "--background-logo-2": "url('/assets/clients-logos/imagine/colibri.png') left center no-repeat",
};

const lgProperties = {
  "--background-primary": "#A60131",
  "--background-primary-light": "#fae0001A",
  "--logo-one-width": "175px",
  "--background-logo": "url('/assets/clients-logos/lg/lg_logo.svg') left center no-repeat",
  "--label-font-color": "#FFFFFF"
};
const missionlocaleparisProperties = {
  "--background-primary": "#DA3239",
  "--background-primary-light": "#fae0001A",
  "--logo-one-width": "200px",
  "--logo-one-height": "50px",
  "--background-logo": "url('/assets/clients-logos/missionlocaleparis/missionlocaleparis_logo.png') left center no-repeat",
  "--label-font-color": "#FFFFFF"
};
const rougnonProperties = {
  "--font-secondary": "#ed8001",
  "--font-quaternary": "#ed8001",
  "--font-quinary": "#000000",
  "--background-primary": "#ed8001",
  "--background-primary-light": "#ed80011A",
  "--error-default": "#ef3e36",
  "light": {
    "--background-logo": "url('/assets/clients-logos/rougnon/rougnon-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/rougnon/rougnon-dark.png') left center no-repeat",
  }
};

const solidaritesProperties = {
  "--background-primary": "#004486",
  "--background-secondary": "#222221",
  "--background-primary-light": "#fae0001A",
  "--background-logo": "url('/assets/MIN_Sante_Prevention_RVB-s.png') left center no-repeat",
};
const tetrisProperties = {
  "--font-primary": "#fff",
  "--font-secondary": "#8B0000",
  "--font-quaternary": "#8B0000",
  "--background-primary": "#E30613",
  "--background-secondary": "#231f20",
  "--background-primary-light": "#8B0000",
  "--error-default": "#EF3E36",
  "--error-light": "#FFCECC",
  "--logo-one-width": "210px",
  "light": {
    "--background-logo": "url('/assets/clients-logos/tetris/tetris_lyon-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/tetris/tetris_lyon-dark.png') left center no-repeat",
  }
};

const vinciFacilitiesProperties = {
  "--font-primary": "#fff",
  "--background-primary": "#004486",
  "--background-secondary": "#222221",
  "--background-primary-light": "#fae0001A",
  "--background-logo": "url('/assets/clients-logos/vinci-facilities/vinci-facilities.svg') left center no-repeat",
};

const alstomatagoraProperties = {
  "--background-primary-light": "#fae0001A",
  "light": {
    "--background-primary": "#1d3245",
    "--background-logo": "url('/assets/clients-logos/alstomatagora/alstomatagora-light.png') left center no-repeat",
  },
  "dark": {
    "--background-primary": "#fdfdfd",
    "--background-logo": "url('/assets/clients-logos/alstomatagora/alstomatagora-dark.png') left center no-repeat",
  }
}

const casvpProperties = {
  "--background-primary": "#004b81",
  "light": {
    "--background-logo": "url('/assets/clients-logos/casvp/casvp-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/casvp/casvp-dark.png') left center no-repeat",
  }
}

const smartkizProperties = {
  "--font-secondary": "#ab1761",
  "--background-primary": "#289B8C",
  "--background-secondary": "#222221",
  "--background-tertiary": "#d95353",
  "--background-primary-light": "#fae0001A",
  "--error-default": "#ef3e36",
  "--background-size": "auto 100%",
  "--logo-one-width": "230px",
  "light": {
    "--background-logo": "url('/assets/clients-logos/smartkiz/smartkiz-new.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/smartkiz/smartkiz-new.png') left center no-repeat",
  }
};

const totalenergiesProperties = {
  "--background-primary": "#ED0100",
  "--logo-one-width": "250px",
  "light": {
    "--background-logo": "url('/assets/clients-logos/totalenergies/totalenergies-light.png') left center no-repeat",
  },
  "dark": {
    "--background-logo": "url('/assets/clients-logos/totalenergies/totalenergies-dark.png') left center no-repeat",
  }
}

const domeTechProperties = {
  "--background-primary": "#4fb98c",
  "--background-secondary": "#4fb98c",

  "--logo-one-width": "230px",
  "--background-logo": "url('/assets/clients-logos/dometech/logo.png') left center no-repeat",
};
const dometechProperties = {
  "--background-primary": "#4fb98c",
  "--background-secondary": "#4fb98c",

  "--logo-one-width": "230px",
  "--background-logo": "url('/assets/clients-logos/dometech/logo.png') left center no-repeat",
};
const lorealProperties = {
  "--background-primary": "#4fb98c",
  "--background-secondary": "#4fb98c",

  "--logo-one-width": "250px",
  "--background-logo": "url('/assets/clients-logos/loreal/logo.svg') left center no-repeat",
};

const systraProperties = {
  "--background-primary": "#DA3239",
  "--background-primary-light": "#fae0001A",

  "--logo-one-width": "280px",
  "--background-logo": "url('/assets/clients-logos/systra/logo.svg') left center no-repeat",
};

const seemfareneitProperties = {
  "--background-primary": "#4fb98c",
  "--background-secondary": "#4fb98c",

  "--logo-one-width": "280px",
  "--background-logo": "url('/assets/clients-logos/seem-fareneit/seem-fareneit.png') left center no-repeat",
};

const seinesaintdenisProperties = {
  "--font-primary": "#fff",
  "--background-primary": "#004486",
  "--background-secondary": "#222221",
  "--background-primary-light": "#fae0001A",

  "--logo-one-width": "230px",
  "--background-logo": "url('/assets/clients-logos/seinesaintdenis/logo.png') left center no-repeat",
};

const salubrisProperties = {
  "--logo-one-width": "250px",
  "--background-logo": "url('/assets/clients-logos/salubris/salubris.png') left center no-repeat",
}

const villedebucProperties = {
  "--logo-one-width": "250px",
  "--background-logo": "url('/assets/clients-logos/villedebuc/villedebuc.svg') left center no-repeat",
}

const humelabProperties  = {
  "--logo-one-width": "250px",
  "--background-logo": "url('/assets/clients-logos/humelab/humelab.png') left center no-repeat",
}

const factoryProperties  = {
  "--logo-one-width": "250px",
  "--background-logo": "url('/assets/clients-logos/factory/factory.svg') left center no-repeat",
}

export const getTheme: (clientName: string, properties) => Theme = (clientName: string, properties) => {
  let currentTheme = localStorage.getItem(LocalStorageKeysEnum.THEME);
  return {
    name: clientName,
    properties: JSON.parse(JSON.stringify(Object.assign(
        currentTheme == 'dark' ? darkTheme.properties : lightTheme.properties, properties,
        currentTheme == 'dark' ? properties['dark'] : properties['light'],
    )))
  }
}
export const ClientsThemes: { [key: string]: Theme } = {
  'sg': getTheme('sg', sgProperties),
  'saclay': getTheme('saclay', saclayProperties),
  'anthalpia': getTheme('anthalpia', anthalpiaProperties),
  'capdeseine': getTheme('capdeseine', capdeseineProperties),
  'croissy': getTheme('croissy', croissyProperties),
  'douane': getTheme('douaneProperties', douaneProperties),
  'rougnon': getTheme('rougnonProperties', rougnonProperties),
  'effysens': getTheme('effysensProperties', effysensProperties),
  'vinci-facilities': getTheme('vinciFacilitiesProperties', vinciFacilitiesProperties),
  'imagine': getTheme('imagineProperties', imagineProperties),
  'hoganlovells': getTheme('hoganlovells', hoganlovellsProperties),
  'solidarites': getTheme('solidarites', solidaritesProperties),
  'electrolux': getTheme('electrolux', electroluxProperties),
  'tetris': getTheme('tetris', tetrisProperties),
  'lg': getTheme('lg', lgProperties),
  'missionlocaleparis': getTheme('missionlocaleparis', missionlocaleparisProperties),
  'alstomatagora': getTheme('alstomatagora', alstomatagoraProperties),
  'casvp': getTheme('casvp', casvpProperties),
  'smartkiz': getTheme('smartkiz', smartkizProperties),
  'totalenergies': getTheme('totalenergies', totalenergiesProperties),
  'dometech':getTheme('dometech', domeTechProperties),
  'leperreux-dometech':getTheme('leperreux-dometech', dometechProperties),
  'vaujours-dometech':getTheme('vaujours-dometech', dometechProperties),
  'cprhlagny-dometech':getTheme('cprhlagny-dometech', dometechProperties),
  'operat-dometech':getTheme('operat-dometech', dometechProperties),
  'loreal':getTheme('loreal', lorealProperties),
  'systra':getTheme('systra', systraProperties),
  'seinesaintdenis':getTheme('seinesaintdenis', seinesaintdenisProperties),
  'seem-fareneit':getTheme('seem-fareneit', seemfareneitProperties),
  'salubris': getTheme('salubris', salubrisProperties),
  'villedebuc': getTheme('villedebuc', villedebucProperties),
  'humelab': getTheme('humelab', humelabProperties),
  'factory': getTheme('factory',factoryProperties)
}
