import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, ElementRef,
  Input, OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { InjectControlPluginDirective } from "./inject-control.directive";
import { ModeControlPluginComponent } from './mode-control-plugin/mode-control-plugin.component';
import { SupplyControlPluginComponent } from './supply-control-plugin/supply-control-plugin.component';
import { PowerControlPluginComponent } from './power-control-plugin/power-control-plugin.component';
import { TemperatureControlPluginComponent } from './temp-control-plugin/temp-control-plugin.component';
import { SpeedControlPluginComponent } from './speed-control-plugin/speed-control-plugin.component';
import { OrientationControlPluginComponent } from './orientation-control-plugin/orientation-control-plugin.component';
import { StatusReviewPluginComponent } from './status-review-plugin/status-review-plugin.component';
import { ScrollMenuControlPluginComponent } from './scroll-menu-control-plugin/scroll-menu-control-plugin.component';
import { CurrentStatusPluginComponent } from './current-status-plugin/current-status-plugin.component';
import { UnifiedScrollMenuControlPluginComponent } from './unified-scroll-menu-control-plugin/unified-scroll-menu-control-plugin.component';
import { PluginControl } from './plugin-control.abstract';
import { PluginControlPoller } from './plugin-control-poller';
import { TimelineService } from 'src/app/components/dashboards/dashboard-timeline/timeline.service';
import { TesseractService } from 'src/app/core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import { TreeService } from 'src/app/core/deprecated/deprecated-services/deprecated-nodes-tree/tree.service';
import { GreeniotService } from 'src/app/core/deprecated/deprecated-services/deprecated-greeniot/greeniot.service';
import { ActionPluginComponent } from './action-plugin/action-plugin.component';
import { DisplayValuePluginComponent } from './display-value-plugin/display-value-plugin.component';
import {
  DeviceOperationEnum
} from "../../../../core/models/site-equipments/equipment/equipment-configuration/device-operation.enum";
import {
  EquipmentConfiguration
} from "../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {SwitchableLightPluginComponent} from "./switchable-light-plugin/switchable-light-plugin.component";
import { SlaValuePluginComponent } from './sla-value-plugin/sla-value-plugin.component';
import { StaticValuePluginComponent } from './static-value-plugin/static-value-plugin.component';
import { PluginControlManual } from './plugin-control-manual';

@Component({
  selector: 'config-control-cell',
  templateUrl: './config-control-cell.component.html',
  styleUrls: ['./config-control-cell.component.scss']
})
/**
 * This component is responsible for rendering the correct Control Plugin component.
 * The component takes the item object and the property  of type ControlOperationTypeEnum needed to view, checks it, and
 * renders the correct elements and inject them in a ng-template in the component template.
 * */
export class ConfigControlCellComponent implements OnInit, AfterViewInit,OnDestroy {
  /**
   * Reference to the injection point. Used when injecting a Control Plugin component.
   */
  @ViewChild(InjectControlPluginDirective, {static: true}) configControlPlugin!: InjectControlPluginDirective;
  /**
   * Control type to view.
   */
  @Input('controlToAdd') controlToAdd: EquipmentConfiguration;

  @Input() withPoller: boolean;

  @Input('templateMode') templateMode: boolean = false;

  pluginControl: PluginControl;
  pluginControlPoller: PluginControlPoller;
  pluginControlManual: PluginControlManual;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private timelineService: TimelineService,
    private tesseractService: TesseractService,
    private changeDetectorRef: ChangeDetectorRef,
    private treeService: TreeService,
    private greenIoTService: GreeniotService,
    private elementRef: ElementRef<HTMLElement>
  ) {
  }

  ngOnInit(): void {
    // this.injectControlPlugin(ModeControlPluginComponent);
    this.renderComponent(this.controlToAdd.operation);
  }

  ngAfterViewInit(): void {

  }

  /**
   * Select which Control Plugin to render, based on (controlToAdd) input.
   */
  renderComponent(operation: DeviceOperationEnum.DeviceOperation) {
    switch(operation) {

      case DeviceOperationEnum.DeviceOperationEnum.MODE:
        this.injectControlPlugin(ModeControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.CURSOR:
        this.injectControlPlugin(SupplyControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.POWER:
        this.injectControlPlugin(PowerControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.SWITCHABLE_LIGHT:
        this.injectControlPlugin(SwitchableLightPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.TEMPERATURE_SET_POINT:
        this.injectControlPlugin(TemperatureControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.FAN_SPEED:
        this.injectControlPlugin(SpeedControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.SWING_POSITION_VERTICAL:
        this.injectControlPlugin(OrientationControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.STATUS:
        this.injectControlPlugin(StatusReviewPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.CURRENT_STATUS:
        this.injectControlPlugin(CurrentStatusPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.DISPLAY_VALUE:
        this.injectControlPlugin(DisplayValuePluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.SCROLLING_MENU:
        this.injectControlPlugin(ScrollMenuControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.UNIFIED_SCROLLING_MENU:
        this.injectControlPlugin(UnifiedScrollMenuControlPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.ACTION:
        this.injectControlPlugin(ActionPluginComponent);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.STATIC_VALUE:
        this.injectControlPlugin(StaticValuePluginComponent, true);
        break;

      case DeviceOperationEnum.DeviceOperationEnum.SLA_VALUE:
        this.injectControlPlugin(SlaValuePluginComponent, true);
        break;

      default:
        this.elementRef.nativeElement.remove();
    }
  }


  /**
   * Dynamically inject a control plugin component to the [configControlPluginHost]
   * directive, then leverage the @Input directive of the injected component
   * to pass configuration data.
   * @param component
   * @param isManual
   */
  injectControlPlugin(component, isManual?: boolean) {

    const viewContainerRef = this.configControlPlugin.viewContainerRef;

    let childComponent = this.componentFactoryResolver.resolveComponentFactory(component);
    viewContainerRef.clear();
    let dynamicComponent = viewContainerRef.createComponent(childComponent);

    dynamicComponent.instance['configuration'] = this.controlToAdd;
    dynamicComponent.instance['templateMode'] = this.templateMode;
    this.pluginControl = <PluginControl>dynamicComponent.instance;

    if (isManual) {
      this.pluginControlManual = new PluginControlManual(this.timelineService, this.pluginControl);
    } else if (this.withPoller) {
      this.pluginControlPoller = new PluginControlPoller(this.timelineService, this.tesseractService, this.changeDetectorRef, this.treeService, this.greenIoTService, this.pluginControl, this.controlToAdd);
    }

  }

  ngOnDestroy(): void {
    this.pluginControlPoller?.ngOnDestroy();
    this.configControlPlugin.viewContainerRef.clear();
  }
}
