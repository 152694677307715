import {ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {PluginControl} from '../plugin-control.abstract';
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {
  EquipmentConfigurationPlugin
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration-plugin";


@Component({
  selector: 'mode-control-plugin',
  templateUrl: './mode-control-plugin.component.html',
  styleUrls: ['./mode-control-plugin.component.scss']
})
export class ModeControlPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  modeOperations: EquipmentConfigurationPlugin.ModeOperation[] = [];
  value: number;
  auto: number;
  operationAsDisplay = {} as EquipmentConfigurationPlugin.ModeOperation;
  subject: Subject<any> = new Subject();
  subscription: Subscription;
  selectedButton: number = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscribeChanges();
    this.auto = this.configuration.additionalInfo?.['auto'];
    this.initializeModes();
    this.setMode(this.auto ? 2 : 1);
  }

  private subscribeChanges() {
    this.subscription = this.subject.pipe(debounceTime(1000)).subscribe((data) => {
      this.setMode(data.mode, data?.initialize);
    });
  }

  private initializeModes() {
    this.modeOperations = [
      {
        name: 'cool',
        operation: null
      },
      {
        name: 'heat',
        operation: null
      },
      {
        name: 'fan',
        operation: null
      },
      {
        name: 'dry',
        operation: null
      }
    ];

    this.modeOperations.forEach((operation: EquipmentConfigurationPlugin.ModeOperation) => {
      operation.operation = this.configuration.additionalInfo?.[operation.name];
    });
    this.assignOperationAsDisplay(this.modeOperations[0]);
  }

  setValue(value: number) {
    this.value = value;
    this.send(this.value, 'initialize');
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    return this.value;
  }




  assignOperationAsDisplay(operation: EquipmentConfigurationPlugin.ModeOperation) {
    if (operation == undefined) {
      this.selectedButton = null;
      return;
    }
    this.operationAsDisplay.name && this.modeOperations.push(JSON.parse(JSON.stringify(this.operationAsDisplay)));
    this.operationAsDisplay = operation;
    this.modeOperations = this.modeOperations.filter((op: EquipmentConfigurationPlugin.ModeOperation) => op.name !== operation.name);

  }


  send(mode: number, initialize?) {
    this.subject.next({
      mode: mode,
      initialize: initialize
    });
  }

  setMode(mode, initialize?) {
    if (initialize) {
      if (mode == this.auto) {
        this.selectedButton = 2;
      } else {
        this.selectedButton = 1;
        (this.operationAsDisplay.operation == mode) ? (this.selectedButton = 1) : (this.assignOperationAsDisplay(this.modeOperations.find((op: EquipmentConfigurationPlugin.ModeOperation) => op.operation == mode)));
      }
    } else {
      this.selectedButton = mode;
      let data: number = mode == 1 ? this.operationAsDisplay.operation : mode == 2 ? this.auto : null;
      data && this.generalSetMode(data);
    }
  }

  generalSetMode(mode: number) {
    this.configuration.setValue = mode;
    if (this.configuration?.additionalSources?.length > 0) {
      this.outputValue.next(mode);
      this.configuration?.additionalSources.forEach((src: EquipmentConfigurationPlugin.Source) => {
        if (src.source !== null) {
          this.outputValue.next({dataId: src?.source, operation: mode});
        }
      });
    } else {
      this.outputValue.next(mode);
    }
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
