import { Injectable, Input } from "@angular/core";
import { Subject } from "rxjs";
import {
  SwitchableLightValue
} from "../../../../core/models/site-equipments/equipment/equipment-configuration/switchable-light-value";

@Injectable()
export abstract class PluginControl {

  @Input() InputValue: number;

  // Emit to parent when value changes.
  outputValue = new Subject<any>();
  outputValueActions = new Subject<SwitchableLightValue>();
  sending: boolean = false;
  /**
   * Set current plugin value.
   * @param value
   */
  abstract setValue(value: number);

  /**
   * Get current plugin value.
   * @param value
   */
  abstract getValue(value: number);

}
